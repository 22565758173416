<template>
	<div class="testimonials w-background-blue-light z-border-radius-20">
		<b-container>
			<b-row class="a-section-title">
				<b-col>
					<h2 class="text-center">
						<span class="w-color-primary z-fancy-underline">
							{{ $t('Odporúčania') }}<svg-fancy-underline-icon />
							<img src="./img/references.png" class="a-small-logo" alt="">
						</span> <span class="w-color-black">{{ fromUserGroup }}</span>
					</h2>
				</b-col>
			</b-row>
			<b-row class="d-flex justify-content-center">
				<b-col v-for="(testimonial, i) of testimonials" :key="i" xl="4" md="6">
					<div class="d-flex justify-content-center h-100">
						<div class="a-card d-flex flex-column w-background-white border">
							<div class="text-center mt-3">
								<img :src="require(`${testimonial.image}`)" />
							</div>
							<div class="mx-4">
								<h4 class="w-color-black mt-4">{{ testimonial.title }}</h4>
								<p class="mt-3 w-color-dark-gray">{{ testimonial.description }}</p>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>	
</template>

<script>
import testimonials 	from './testimonials.json'

export default {

	components: {
		'svg-fancy-underline-icon': () => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline')
	},

	computed: {
		testimonials() {
			return testimonials.teachers
			// return this.$route.path == '/pre-ucitelov' ? testimonials.teachers : testimonials.students
		},
		fromUserGroup() {
			return this.$t('od učiteľov')
			// return this.$route.path == '/pre-ucitelov' ? this.$t('od učiteľov') : this.$t('od študentov')
		}
	}
}
</script>

<style lang="sass" scoped>
.testimonials
	margin: 60px auto
	max-width: 1634px

	@media only screen and (max-width: 575px)
		margin: 2rem auto

.a-card
	min-height: 360px
	margin-bottom: 1rem
	border-radius: 10px
	box-shadow: 0px 20px 40px rgba(208, 226, 235, 0.6)
</style>